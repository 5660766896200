<template>
  <div style="background-color: #eaf2ff;">
    <!-- 顶部导航栏组件 -->
    <HeaderPage chackKey="about"></HeaderPage>
    <!-- AboutPage页内容 -->
    <div class="container">
      <!-- contact us 区域 -->
      <div class="contact" id="contact">
        <div class="bgBlack"></div>
        <div class="contact_text">
          <p class="p1">About</p>
          <p class="p2">For the Love of the Game</p>
          <p class="p3">E-TECH, INC. was established in 2023 to craft <br> interactive entertainment to the mobile world.
          </p>
          <p class="p4">We are Tre Studio, an up and coming studio. Welcome to our world of casual games with a difference!</p>
          <p class="p4">Each of our members is a master of casual gaming, all with over five years of casual gaming experience. We've had a lot of fun with our games, and now we want to bring that fun to everyone who plays our games!</p>
        </div>
      </div>
      <!-- Quick 区域 -->
      <div class="job">
        <div class="jobBox">
          <div class="job1">
            <h2>Quick Facts</h2>
            <div class="quickBox">
              <div class="box1 wow animate__pulse">
                <h1>24</h1>
                <p>Team Members</p>
              </div>
              <div class="box2 wow animate__pulse">
                <h1>6</h1>
                <p>Years of Experience</p>
              </div>
              <div class="box3 wow animate__pulse">
                <h1>110%</h1>
                <p>Growth Rate</p>
              </div>
            </div>
            <img src="../../assets/image/toplogo2.png" alt="">
          </div>
          <div class="job2">
            <h2>We’re based in Barcelona</h2>
            <img src="../../assets/image/About.png" alt="">
            <p>Each of our members is a master of casual gaming, all with over five years of casual gaming experience. We've had a lot of fun with our games, and now we want to bring that fun to everyone who plays our games!</p>
          </div>
        </div>
      </div> 
      <!-- Join Team区域 -->
      <div class="joinTeam">
        <div class="teamBg"></div>
        <div class="teamBox">
          <h2>Join Our Team</h2>
          <p>It takes the world's best talent to change the game.</p>
          <button>Explore Opportunities</button>
        </div>
        <img class="about2" src="../../assets/image/About 2.png" alt="">
      </div>
    </div>
    <!-- 底部组件 -->
    <FooterPage></FooterPage>
  </div>
</template>

<script>
import HeaderPage from "@/components/headerPage/index.vue"
import FooterPage from "@/components/footerPage/index.vue"

export default {
  name: "AboutPage",
  components: {
    HeaderPage,
    FooterPage,
  },
  mounted(){
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
  }
}
</script>

<style lang="scss" scoped>
.container {
  .contact {
    width: 100%;
    height: 960px;
    padding-left: 10%;
    padding-top: 10%;
    // height: 800px;
    background-image: url("../../assets/image/About 1.png");
    // background-position: 0 -100px;
    background-size: cover;
    position: relative;

    .bgBlack {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #19395c;
      opacity: .7;
    }

    .contact_text {
      position: relative;
      z-index: 2;

      p {
        color: #fff;
        font-weight: bolder;
      }

      .p1 {
        font-size: 18px;
      }

      .p2 {
        font-size: 70px;
        margin: 30px 0;
      }

      .p3 {
        font-size: 28px;
      }

      .p4 {
        display: block;
        width: 670px;
        font-size: 15px;
        margin: 20px 0;
      }

      .contact_btn {
        margin-top: 30px;
        width: 140px;
        height: 60px;
        border-radius: 60px;
        border: 3px solid #fff;
        transition: background-color .3s;
        cursor: pointer;
      }

      .contact_btn:hover {
        color: #fff;
        background-color: #a600f9;
      }
    }

    .contact_box {
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 50px;
      height: 410px;

      img {
        width: 322px;
        height: auto;
      }

      .contact_right {
        width: 490px;
        height: 655px;
        background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        color: #00204c;

        h3 {
          font-size: 24px;
        }

        p {
          font-size: 15px;
          line-height: 30px;
          margin-bottom: 50px;
        }
      }
    }
  }
  .job{
    position: relative;
    top: -200px;
    width: 76%;
    margin: 0 auto 200px;
    background-color: #eaf2ff;
    .jobBox{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 9;
      div{
        width: 50%;
        height: 720px;
        background-color: #eaf2ff;
        strong{
          font-size: 18px;
        }
        p{
          font-size: 15px;
          color: #00204c;
        }
        .job_btn{
          background-color: #00204c;
          border: 3px solid #00204c;
          color: #fff;
          border-radius: 60px;
          width: 150px;
          height: 60px;
          margin: 30px auto;
          transition: background-color .3s;
          cursor: pointer;
        }
        .job_btn:hover{
          background-color: #fff;
          color: #00204c;
        }
      }
      .job1{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        img{
          width: 170px;
          height: 168px;
          position: absolute;
          bottom: -150px;
          left: 50%;
          transform: translate(-50%);
        }
        h2{
          display: block;
          width: 390px;
          margin: 0 auto;
          font-size: 44px;
          color: #00204c;
        }
        .quickBox{
          width: 390px;
          height: 420px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          div{
            height: 152px;
          }
          .box1,.box2{
            background-color: #fff;
            border-radius: 20px;
            color: #00204c;
            width: 180px;
            height: 152px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 9px 9px 5px #999;
            h1{
              font-size: 70px;
              margin: 10px 0;
            }
            p{
              font-size: 14px;
            }
          }
          .box3{
            background-color: #fff;
            border-radius: 20px;
            color: #00204c;
            width: 100%;
            height: 152px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 9px 9px 5px #999;
            h1{
              font-size: 70px;
              margin: 10px 0;
            }
            p{
              font-size: 14px;
            }
          }
        }
      }
      .job2{
        background-color: #a600f9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        h2{
          font-size: 44px;
          color: #fff;
          width: 322px;
        }
        img{
          width: 388px;
          height: 232px;
        }
        p{
          width: 388px;
          font-size: 15px;
          color: #fff;
          line-height: 30px;
        }
      }
    }
  }
  .joinTeam{
    position: relative;
    width: 95vw;
    height: 85vh;
    margin: 0 auto;
    background-image: url("../../assets/image/Home 3.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .teamBg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #a600f9;
      opacity: .7;
    }
    .teamBox{
      position: absolute;
      z-index: 9;
      padding-top: 20vh;
      text-align: center;
      h2{
        font-size: 44px;
        color: #fff;
      }
      p{
        font-size: 20px;
        color: #fff;
        margin: 3vh;
      }
      button{
        font-size: 14px;
        width: 200px;
        height: 60px;
        border: 3px solid #fff;
        color: #00204c;
        border-radius: 60px;
        cursor: pointer;
        transition: background-color .3s ease;
      }
      button:hover{
        background-color: #fff0;
        color: #fff;
      }
    }
    .about2{
      position: absolute;
      bottom: -12vh;
      width: 14vw;
      height: auto;
      z-index: 99;
    }
  }
}

@media (max-width: 768px) {
  .container {
    .contact {
      width: 100%;
      height: 900px;
      background-position: center;
      background-size: cover;

      .contact_text {
        p{
          display: block;
          max-width: 270px;
        }
        .p1 {
          font-size: 16px;
        }

        .p2 {
          font-size: 30px;
          margin: 30px 0;
        }

        .p3 {
          font-size: 18px;
        }

        .p4 {
          display: block;
          width: 670px;
          font-size: 15px;
          margin: 20px 0;
        }
      }
    }
    .job{
      width: 100%;
      height: 500px;
      top: 0;
      margin-bottom: 870px;
      .jobBox{
        div{
          width: 80%;
          margin: 30px auto;
        }
        .job1{
          width: 100%;
          height: 540px;
          margin-bottom: 50px;
          h2{
            text-align: center;
          }
          img{
            width: 108px;
            height: 109px;
            bottom: -70px;
          }
        }
        .job2{
          width: 100%;
          h2{
            font-size: 25px;
          }
          p{
            width: 80%;
          }
          img{
            width: 80%;
            height: auto;
          }
        }
      }
    }
    .joinTeam{
      width: 100%;
      .about2{
        width: 47vw;
      }
    }
  }

}
</style>