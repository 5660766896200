<template>
  <div class="container">
    <div class="footerBox">
     
      <hr>
      <div class="center_box">
        <div class="centerBox_left">
          <h2>Contact</h2>
          <p>etechinc@belongsgame.com</p>
          <div>
            <img src="../../assets/image/Appstore.png" alt="">
            <img src="../../assets/image/Google Play.png" alt="">
          </div>
        </div>
        <div class="centerBox_cen">
          <h2>Subscribe for News and Updates</h2>
          <el-form :model="Form" status-icon :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
              <el-form-item label="Enter your email here" prop="Email">
                <el-input v-model="Form.Email" autocomplete="off"></el-input>
                <el-button type="primary" style="background-color: #7f03c7; border: none;" @click="submitForm('ruleForm')">submit</el-button>
              </el-form-item>
            </el-form>
        </div>
        <div class="centerBox_right">
          <h2>Menu</h2>
          <ul>
            <li><a style="text-decoration: none;" @click="toScroll('game')">Games</a></li>
            <li><a style="text-decoration: none;" @click="toPage('/careers')">Careers</a></li>
            <li><a style="text-decoration: none;" @click="toPage('/about')">About</a></li>
            <li><a style="text-decoration: none;" @click="toScroll('contact')">Contact</a></li>
            <li><a style="text-decoration: none;" @click="toPage('/politica_de_privacidad')">Privacy Policy</a></li>
            <li><a style="text-decoration: none;" @click="toPage('/terminos_servicio')">Terms & Conditions</a></li>
            <li>
              <i class="iconfont icon-discord"></i>
              <i class="iconfont icon-facebookfacebook51"></i>
              <i class="iconfont icon-Youtube"></i>
              <i class="iconfont icon-tuitetwitter44"></i>
            </li>
          </ul>
        </div>
      </div>
      <div class="copyright">
        <h2>© 2023 by E-TECH, INC.</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"FooterPage",
    data(){
      return{
        Form:{},
        rules: {
          Email: [{ required: true, message: 'Email is a required field', trigger: 'blur' },],
          }
      }
    },
  mounted(){
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
  },
    methods:{
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let mailto = 'mailto:etechinc@belongsgame.com?body=' + this.Form;
            window.location.href = mailto;
          } else {
            return false;
          }
        });
      },
      toPage(key) {// 用于页面跳转
      this.$router.push({ 'path': key }).catch(() => { })
    },
    toScroll(id){
      if ( this.$route.name.includes('careers') || this.$route.name.includes('about') || this.$route.name.includes('politica_de_privacidad') || this.$route.name.includes('terminos_servicio')) {
        this.$router.push({ 'path': "/home" }).catch(() => { })
      }
      setTimeout(()=>{
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth'
            });
            this.drawer = false;
      }
      },500)
    }
    }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  padding-top: 10%;
}
h2{
  color: #00204c;
}
p{
  color: #00204c;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.footerBox{
  padding: 0 10%;
  .header_left_logo {
    width: 260px;
    font-size: 21px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
    }
    background-color: #fff;
    border-radius: 25px;
    height: 90px;
    padding: 0 30px;
    box-shadow: 9px 9px 5px #999;
  }
  p{
    line-height: 50px;
    margin: 50px 0;
    font-weight: bolder;
  }
  .center_box{
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .centerBox_left{
      div{
        img{
          width: 120px;
          height: 40px;
          margin-right: 20px;
        }
      }
    }
    .centerBox_cen{
      ::v-deep .el-form-item{
        display: flex;
        flex-direction: column;
      }
      ::v-deep .el-form-item__label{
        text-align: left;
        margin: 30px 0 10px;
      }
      ::v-deep .el-form-item__content{
        margin-left: 0 !important;
        display: flex;
        flex-wrap: wrap;
      }
      ::v-deep .el-input__inner{
        margin-bottom: 10px;
      }
    }
    .centerBox_right{
      width: 300px;
      ul{
        margin-top: 30px;
        li{
          list-style-type: none;
          line-height: 30px;
          a{
            font-size: 15px;
            color: #000;
            font-weight: bolder;
            transition: color .3s;
            cursor: pointer;
          }
          a:hover{
            color: #7f03c7;
          }
          .iconfont{
            font-size: 30px;
            cursor: pointer;
            transition: color .3s;
            margin-right: 10px;
          }
          .iconfont:hover{
            color: #7f03c7;
          }
        }
      }
    }
  }
  .copyright{
    padding: 30px 0;
  }
}
@media (max-width: 768px) {
  h2{
    font-size: 18px;
    margin-top: 30px;
  }
.footerBox{
  .header_left_logo{
    width: 175px;
    height: 53px;
    font-size: 18px;
    padding: 0 0 0 10px;
    img{
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
  p{
    font-size: 20px;
  }
  .center_box{
    .centerBox_left{
      p{
        margin: 0;
      }
      div{
        img{
          display: none;
        }
      }
    }
  }
}
}
</style>