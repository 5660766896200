<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import url("./utils/css/iconfont.css"); 
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
#app{
  width: 100%;
  background-color: #eaf2ff;
  height: 100vh;
}
::-webkit-scrollbar {
width: 0 !important;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.color3{
  color: #333;
}
.color6{
  color: #666;
}
.color9{
  color: #999;
}
</style>
