<template>
  <div class="container">
    <div class="header_top" id="home">
      <div class="header_left_logo" @click="toPage('/home')">
        <img src="../../assets/image/toplogo.png" alt=""> E-TECH, INC.
      </div>
      <ul class="header_right_menu" v-show="!flag">
        <li><a class="home" @click="toPage('/home')">Home</a></li>
        <li><a class="game" @click="toScroll('game')">Game</a></li>
        <li><a class="careers" @click="toPage('/careers')" href="">Careers</a></li>
        <li><a class="about" @click="toPage('/about')" href="">About</a></li>
        <li><a class="contact" @click="toScroll('contact')">Contact</a></li>
      </ul>
      <!-- 移动端显示内容 -->
      <el-button class="menuBtn" v-show="flag" @click="drawer = true" type="plian"> MENU </el-button>
      <el-drawer title="MENU" :visible.sync="drawer" :with-header="false" direction="ttb" size="50%">
        <ul class="header_right_menu" style="display: block; padding-left: 20px;">
          <li class="home" @click="toPage('/home')">Home</li>
          <li class="game" @click="toScroll('game')">Game</li>
          <li class="careers" @click="toPage('/careers')">Careers</li>
          <li class="about" @click="toPage('/about')">About</li>
          <li class="contact" @click="toScroll('contact')">Contact</li>
        </ul>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderPage",
  data() {
    return {
      drawer: false,
      flag:false,
    };
  },
  mounted(){
    this.clickMenu();
    if (window.innerWidth < 768 || window.innerWidth < 768) {
      this.flag = true;
    }else{
      this.flag = false;
    }
    this.$nextTick(() => {
      new this.$wow.WOW().init();
    });
  },
  methods: {
    clickMenu() {
      let className = this.$route.name;
      let Node = document.getElementsByClassName(className);
      Node[0].classList.add("active");
    },
    toPage(key) {// 用于页面跳转
      this.$router.push({ 'path': key }).catch(() => { })
    },
    toScroll(id){
      if ( this.$route.name.includes('careers') || this.$route.name.includes('about')) {
        this.$router.push({ 'path': "/home" }).catch(() => { })
      }
      setTimeout(()=>{
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth'
            });
            this.drawer = false;
      }
      },1)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 90%;
  line-height: 149px;
  margin: 0 auto;
}

.header_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  .menuBtn{
    box-shadow: 9px 9px 5px #999;
    background-color: #fff;
  }
  .header_left_logo {
    font-size: 21px;
    font-weight: bolder;
    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
    }

    background-color: #fff;
    border-radius: 25px;
    height: 90px;
    padding: 0 30px;
    box-shadow: 9px 9px 5px #999;
  }

  .header_right_menu {
    width: 350px;
    display: flex;

    li {
      list-style: none;
      font-size: 2em;
      a{
        text-decoration: none;
        display: block;
        width: 70px;
        line-height: 60px;
        font-size: 14px;
        font-weight: bolder;
        transition: color .3s;
        color: #000;
        cursor: pointer;
      }
    }

    li{
      a:hover {
        color: #a700fa;
      }
    }

    .active {
      color: #a700fa;
    }
  }
}
@media (max-width: 768px) {

}
</style>